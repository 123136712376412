import './password-reset.scss';
import Header from '../../../organisms/header/header.js';
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from '../../../atoms/input.js';
import { useForm, Controller } from 'react-hook-form';
import Joi from 'joi';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

function PasswordReset() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { token } = useParams();
  const [passwordResetCompleted, setPasswordResetCompleted] = useState(false);
  const { control, formState, handleSubmit, watch } = useForm();
  const { errors } = formState;
  const passwordValue1 = watch('password1');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    // Check if there's a preferred language in the query params
    const params = new URLSearchParams(location.search);
    const preferredLanguage = params.get('preferredLanguage');
    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage.toLowerCase());
    }
  }, [location.search, i18n]);

  const onSubmit = handleSubmit(async (data) => {
    const newPassword = data.password1;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/auth/new-password`,
        { password: newPassword },
        config
      );
      setPasswordResetCompleted(true);
    } catch (error) {
      setSnackbarOpen(true);
    }
  });

  return (
    <div className="App">
      <Header />
      <main>
        {!passwordResetCompleted && (
          <section className="password-recovery">
            <div className="inner">
              <h2>{t('password-reset-title')}</h2>
              <p>{t('password-reset-text')}</p>
              <form onSubmit={onSubmit}>
                <div className="form-control">
                  <label htmlFor="password1">{t('common-key')}</label>

                  <Controller
                    name="password1"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        maxLength="50"
                        type="password"
                        id="password1"
                        error={errors?.password1}
                        {...field}
                      />
                    )}
                    rules={{
                      validate: (v) => {
                        const { error } = Joi.string()
                          .regex(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{12,}$/
                          )
                          .required()
                          .error(
                            () =>
                              new Error(
                                t('password-reset-error-patternpassword')
                              )
                          )
                          .validate(v);
                        return error?.message ? error.message : true;
                      },
                    }}
                  />
                </div>
                <p>{t('password-reset-condition-text')}</p>
                <ul>
                  <li>{t('password-reset-condition1')}</li>
                  <li>{t('password-reset-condition2')}</li>
                  <li>{t('password-reset-condition3')}</li>
                </ul>
                <div className="form-control">
                  <label htmlFor="password2">{t('common-repeat-key')}</label>

                  <Controller
                    name="password2"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        maxLength="50"
                        type="password"
                        id="password2"
                        error={errors?.password2}
                        {...field}
                      />
                    )}
                    rules={{
                      validate: (v) => {
                        if (v !== passwordValue1) {
                          return t('password-reset-error-matchpassword');
                        }
                        return true;
                      },
                    }}
                  />
                </div>
                <button className="btn" type="submit">
                  {t('password-reset-action')}
                </button>
              </form>
            </div>
          </section>
        )}
        {passwordResetCompleted && (
          <section className="password-recovery">
            <div className="inner">
              <h2>{t('password-reset-title2')}</h2>
              <p>{t('password-reset-text2a')}</p>
              <p>{t('password-reset-text2b')}</p>
              <div className="actions">
                <Link className="btn" to="/login">
                  {t('common-login')}
                </Link>
              </div>
            </div>
          </section>
        )}
      </main>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {t('common-internal-server-error')}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default PasswordReset;
